import "./App.css";

import { ChakraProvider, extendBaseTheme } from "@chakra-ui/react";

import { LightMode } from "@chakra-ui/react";

import Dashboard from "./views/Dashboard";

import theme from "./theme";

function App() {
    // 2. Wrap ChakraProvider at the root of your app
    return (
        <>
            <LightMode>
                <ChakraProvider theme={theme}>
                    <Dashboard />
                </ChakraProvider>
            </LightMode>
        </>
    );
}

export default App;
