import React, { ReactNode } from "react";
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
    BoxProps,
    FlexProps,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    Select,
    Button,
    Switch,
    Divider,
    Stack,
} from "@chakra-ui/react";

import CodeBlock from "./CodeBlock";

import { HomeIcon } from "@heroicons/react/24/outline";

// import { IconType } from "react-icons";
import { ReactText } from "react";

import { useStore } from "../store";

const LinkItems = [
    { name: "Home", icon: HomeIcon },
    { name: "Trending", icon: HomeIcon },
    { name: "Explore", icon: HomeIcon },
    { name: "Favourites", icon: HomeIcon },
    { name: "Settings", icon: HomeIcon },
];

const MyComponent = (graphData) => {
    // Convert graphData dict to JSON
    const graphDataJSON = JSON.stringify(graphData);
    console.log(graphData.graphData);

    // Build JS code from graphData.nodes and graphData.edges.
    let jsCode = `nodes =`;
    jsCode += JSON.stringify(graphData.graphData.nodes, null, 4);
    // jsCode += `);`;

    jsCode += `\n\nedges = `;
    jsCode += JSON.stringify(graphData.graphData.edges, null, 4);
    // jsCode += `);`;

    return <CodeBlock code={jsCode} />;
};

function Rightbar(props, { children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box
            pos={"fixed"}
            bg={"white"}
            alignContent="end"
            // borderLeft="1px"
            // borderLeftColor={"gray.200"}
        >
            <Box p={5}>
                <Stack spacing={5}>
                    <Text fontSize="lg">Generated code</Text>
                    <Text fontSize="xs">
                        Use the following code to define nodes and edges in your
                        CausaDB model.
                    </Text>
                    <MyComponent graphData={props.graphData} />
                </Stack>
            </Box>
        </Box>
    );
}

export default Rightbar;
