import React from "react";
import {
    Box,
    useColorModeValue,
    Button,
    useClipboard,
    Tooltip,
} from "@chakra-ui/react";
import { Highlight } from "prism-react-renderer";
// import theme from "prism-react-renderer/themes/nightOwl";

const CodeBlock = ({ code }) => {
    const bgColor = useColorModeValue("gray.100", "gray.700"); // Change the color based on the theme
    const { hasCopied, onCopy } = useClipboard(code);

    return (
        <Box position="relative" my={4}>
            <Highlight code={code.trim()} language="javascript">
                {({
                    className,
                    style,
                    tokens,
                    getLineProps,
                    getTokenProps,
                }) => (
                    <Box
                        as="pre"
                        className={className}
                        style={{ ...style, whiteSpace: "pre-wrap" }}
                        bg={bgColor}
                        borderRadius="md"
                        p={4}
                        overflowX="auto"
                        overflowY="scroll"
                        maxH="40vh"
                        fontSize={"xs"}
                    >
                        {tokens.map((line, i) => (
                            <div key={i} {...getLineProps({ line, key: i })}>
                                {line.map((token, key) => (
                                    <span
                                        key={key}
                                        {...getTokenProps({ token, key })}
                                    />
                                ))}
                            </div>
                        ))}
                    </Box>
                )}
            </Highlight>
            <Tooltip
                label={hasCopied ? "Copied!" : "Copy to clipboard"}
                closeOnClick={false}
            >
                <Button
                    size="sm"
                    position="absolute"
                    top={2}
                    right={2}
                    onClick={onCopy}
                >
                    {hasCopied ? "Copied" : "Copy"}
                </Button>
            </Tooltip>
        </Box>
    );
};

export default CodeBlock;
