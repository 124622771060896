import { useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Diagram from "./Diagram";
import Rightbar from "./Rightbar";
import RightbarUpload from "./RightbarUpload";
import DiagramControls from "./DiagramControls";
import DataTable from "./DataTable";
import RightbarAnalyse from "./RightbarAnalyse";
import DataChart from "./DataChart";
// import Graph from "./Graph";
import Flow from "./Flow";

import axios from "axios";

import { ReactFlowProvider } from "reactflow";

import { ChevronRightIcon } from "@chakra-ui/icons";
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Stack,
    Card,
    CardHeader,
    CardBody,
    Grid,
    GridItem,
    Box,
    Button,
    Icon,
    IconButton,
    HStack,
    VStack,
    Text,
    AbsoluteCenter,
    Center,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Code,
} from "@chakra-ui/react";

import { useStore } from "../store";

function fitModel(
    graphData,
    data,
    setModelSummary,
    setCurrentPanel,
    setQueryResponse,
    setIsModelFitting,
    setTabIndex,
    setModelID
) {
    const edges = graphData.edges.map((edge) => {
        return { source: edge.source, target: edge.target };
    });
    console.log(edges);
    console.log("I get here!!!!");
    setQueryResponse(null);
    setTabIndex(0);

    console.log("Setting isModelFitting to true");

    setIsModelFitting(true);

    console.log("Sending API request to fit model");
    console.log("More logs");
    console.log(edges);
    console.log(data);
    // Send API request to fit model using Axios
    axios
        .post(process.env.REACT_APP_API_URL + "/fit", {
            edges: edges,
            data: data,
        })
        .then((res) => {
            console.log(res.data);
            setModelSummary(res.data.model_summary);
            setModelID(res.data.model_id);
            console.log("Setting Model ID: " + res.data.model_id);
            setCurrentPanel("analyse");
            setIsModelFitting(false);
        })
        .catch((err) => {
            console.log(err);
            setIsModelFitting(false);
        });
}

function Dashboard() {
    const [currentPanel, setCurrentPanel] = useState("upload");
    const [fileUploaded, setFileUploaded] = useState(false);
    // const [data, setData] = useState({});
    const data = useStore((state) => state.data);
    const setData = useStore((state) => state.setData);
    // const graphData = useStore((state) => state.graphData);
    // const setGraphData = useStore((state) => state.setGraphData);
    const modelSummary = useStore((state) => state.modelSummary);
    const setModelSummary = useStore((state) => state.setModelSummary);

    const queryResponse = useStore((state) => state.queryResponse);
    const setQueryResponse = useStore((state) => state.setQueryResponse);
    const [isModelFitting, setIsModelFitting] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);
    const [modelID, setModelID] = useState(null);

    const [graphData, setGraphData] = useState({ nodes: [], edges: [] });

    const handleTabsChange = (index) => {
        setTabIndex(index);
    };

    return (
        <>
            <Navbar>
                <Grid
                    templateAreas={`"main rightnav"`}
                    gridTemplateRows={"500px"}
                    gridTemplateColumns={"1fr 320px"}
                    h="90vh"
                    gap="1"
                    color="blackAlpha.700"
                    backgroundColor="white"
                    fontWeight="bold"
                    pt="64px"
                >
                    <GridItem area={"main"} h="90vh">
                        <Box p="4" h="90vh">
                            <Stack direction="column" spacing={8} h="90vh">
                                {/* <Diagram
                                    data={data}
                                    setGraphData={setGraphData}
                                />
                                <DiagramControls /> */}
                                <ReactFlowProvider>
                                    <Flow setGraphData={setGraphData} />
                                </ReactFlowProvider>
                            </Stack>
                        </Box>
                    </GridItem>
                    <GridItem area={"rightnav"}>
                        <Rightbar
                            setCurrentPanel={setCurrentPanel}
                            fitModel={() => {
                                fitModel(
                                    graphData,
                                    data,
                                    setModelSummary,
                                    setCurrentPanel,
                                    setQueryResponse,
                                    setIsModelFitting,
                                    setTabIndex,
                                    setModelID
                                );
                            }}
                            isModelFitting={isModelFitting}
                            graphData={graphData}
                        />
                    </GridItem>
                </Grid>
            </Navbar>
        </>
    );
}

export default Dashboard;
