import {
    Box,
    Flex,
    Avatar,
    AvatarBadge,
    HStack,
    Link,
    Icon,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack,
    Text,
    Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, QuestionIcon } from "@chakra-ui/icons";

import HelperInstructions from "./HelperInstructions";

const NavLink = ({ href, children }) => (
    <Link
        px={2}
        py={1}
        rounded={"md"}
        _hover={{
            textDecoration: "none",
            bg: "gray.600",
        }}
        href={href}
    >
        {children}
    </Link>
);

function Navbar({ children }) {
    const { isOpen, onToggle } = useDisclosure();

    return (
        <>
            <Box bg={"gray.900"} px={4} pos="fixed" w="100%" zIndex={200}>
                <Flex
                    h={16}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <IconButton
                        size={"md"}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={"Open Menu"}
                        display={{ md: "none" }}
                        // onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={"center"}>
                        <Box w={320}>
                            {/* Add icon */}
                            <HStack>
                                <Image
                                    src="/logo_light.svg"
                                    alt="causa logo"
                                    boxSize={160}
                                />
                                <Text
                                    fontSize="2xl"
                                    pt={1}
                                    fontWeight="medium"
                                    color="white"
                                >
                                    Model Builder
                                </Text>
                            </HStack>
                        </Box>
                        <HStack
                            as={"nav"}
                            spacing={4}
                            display={{ base: "none", md: "flex" }}
                            color="white"
                        >
                            <NavLink key={"home"} href={"https://causa.tech"}>
                                Home
                            </NavLink>
                            <NavLink
                                key={"docs"}
                                href="https://docs.causadb.com"
                            >
                                Documentation
                            </NavLink>
                        </HStack>
                    </HStack>
                    <Flex alignItems={"center"}>
                        <HStack spacing={2}>
                            <Box
                                bg="teal.600"
                                color="white"
                                fontWeight="bold"
                                fontSize="sm"
                                p={1}
                                px={2}
                                borderRadius="full"
                            >
                                BETA
                            </Box>
                            <HelperInstructions />
                        </HStack>
                    </Flex>
                </Flex>

                {/* {isOpen ? (
                    <Box pb={4} display={{ md: "none" }}>
                        <Stack as={"nav"} spacing={4}>
                            {Links.map((link) => (
                                <NavLink key={link}>{link}</NavLink>
                            ))}
                        </Stack>
                    </Box>
                ) : null} */}
            </Box>

            <Box>{children}</Box>
        </>
    );
}

export default Navbar;
