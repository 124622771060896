import React, { useState } from "react";
import {
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Button,
    Text,
} from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";

const HelperInstructions = () => {
    const [isOpen, setIsOpen] = useState(false);
    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);

    return (
        <Popover
            returnFocusOnClose={false}
            isOpen={isOpen}
            onClose={close}
            placement="right"
            closeOnBlur={false}
        >
            <PopoverTrigger>
                <Button
                    onClick={open}
                    variant="ghost"
                    _hover={{
                        textDecoration: "none",
                        bg: "gray.600",
                    }}
                >
                    <Icon as={QuestionIcon} boxSize={6} color="gray.400" />
                </Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Instructions</PopoverHeader>
                <PopoverBody>
                    <Text fontSize="sm">
                        This is the CausaDB model builder. You can build a
                        causal model by adding nodes and edges to the canvas.
                        When you are done, use the generated code in your
                        CausaDB client script to run the model.
                    </Text>
                    <br />
                    <Text fontSize="sm">
                        Add nodes by double clicking on the canvas. Connect
                        nodes by dragging from one node point to another. Delete
                        nodes and edges by selecting them and pressing the
                        delete key (backspace on Mac).
                    </Text>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

export default HelperInstructions;
