import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useStore = create(
    persist(
        (set) => ({
            data: null,
            graphData: null,
            queryResponse: null,
            modelSummary: null,
            setData: (data) => {
                set({ data: data });
            },
            setGraphData: (graphData) => {
                set({ graphData: graphData });
            },
            setQueryResponse: (queryResponse) => {
                set({ queryResponse: queryResponse });
            },
            setModelSummary: (modelSummary) => {
                set({ modelSummary: modelSummary });
            },
        }),
        {
            name: "compass",
        }
    )
);
