import React, { useContext, useEffect, useState } from "react";
// import { Button } from "@chakra-ui/react";
import Graphin, { Behaviors, GraphinContext, Components } from "@antv/graphin";

// Import CreateEdge from Graphin
import { CreateEdge } from "@antv/graphin-components";

import { Menu, message } from "antd";

import { useStore } from "../store";
import { m } from "framer-motion";

const { ZoomCanvas, ClickSelect, ActivateRelations, BrushSelect } = Behaviors;

const { ContextMenu } = Components;

const data = {
    nodes: [
        {
            id: "node-0",
            label: "node-0",
            style: {
                label: {
                    value: "W",
                    offset: [0, 5],
                },
                keyshape: {
                    fill: "#fff",
                    stroke: "#666",
                    lineWidth: 2,
                },
            },
        },
        {
            id: "node-1",
            label: "node-1",
            style: {
                label: {
                    value: "X",
                    offset: [0, 5],
                },
                keyshape: {
                    fill: "#90CDF4",
                    fillOpacity: 1,
                    stroke: "#666",
                    lineWidth: 2,
                },
            },
        },
        {
            id: "node-2",
            label: "node-2",
            style: {
                label: {
                    value: "Y",
                    offset: [0, 5],
                },
                keyshape: {
                    fill: "#9AE6B4",
                    fillOpacity: 1,
                    stroke: "#666",
                    lineWidth: 2,
                },
            },
        },
        {
            id: "node-3",
            label: "node-3",
            style: {
                label: {
                    value: "Z",
                    offset: [0, 5],
                },
                keyshape: {
                    fill: "#fff",
                    stroke: "#666",
                    lineWidth: 2,
                },
            },
        },
    ],
    edges: [
        // // Add circle-running animation to this edge
        // {
        //     source: "node-1",
        //     target: "node-0",
        //     style: {
        //         animate: {
        //             type: "circle-running",
        //             repeat: true,
        //             duration: 2000,
        //         },
        //     },
        // },
        // {
        //     source: "node-0",
        //     target: "node-2",
        //     style: {
        //         animate: {
        //             type: "circle-running",
        //             repeat: true,
        //             duration: 3000,
        //         },
        //     },
        // },
        // {
        //     source: "node-3",
        //     target: "node-0",
        //     style: {
        //         animate: {
        //             type: "circle-running",
        //             repeat: true,
        //             duration: 3000,
        //         },
        //     },
        // },
        // {
        //     source: "node-1",
        //     target: "node-3",
        //     style: {
        //         animate: {
        //             type: "circle-running",
        //             repeat: true,
        //             duration: 3000,
        //         },
        //     },
        // },
    ],
};

const layoutConfig = {
    // type: "concentric",
    preventOverlap: true,
    nodeSize: 30,
    nodeSpacing: 100,
    // animation: true,
};

function TestBehaviour() {
    const { graph, apis } = useContext(GraphinContext);

    useEffect(() => {
        graph.on("edge:mouseenter", (e) => {
            const { item } = e;
            graph.setItemState(e.item, "active", true);
        });

        graph.on("edge:mouseleave", (e) => {
            const { item } = e;
            graph.setItemState(e.item, "active", false);
        });
    }, []);
}

const MyMenu = (value) => {
    const handleClick = (e) => {
        const { onClose, id, item } = value;
        // message.info(`${e.key}:${id}`);
        message.info(`Delete edge ${e.source} -> ${e.target}`);
        console.log(item._cfg.model);
        onClose();
    };

    return (
        <Menu onClick={handleClick}>
            <Menu.Item key="delete">Delete</Menu.Item>
        </Menu>
    );
};

function Diagram(props) {
    const setGraphData = useStore((state) => state.setGraphData);
    const graphData = useStore((state) => state.graphData);

    return (
        <div style={{ height: "800px" }}>
            <Graphin data={graphData} layout={layoutConfig}>
                <ZoomCanvas sensitivity={0.1} />
                <CreateEdge
                    active={true}
                    onChange={(e) => {
                        // Add edge to graphData edges state. Circle-running animation with large circle.
                        console.log(e);
                        const newEdges = e.map((edge) => {
                            const newEdge = {
                                source: edge.source,
                                target: edge.target,
                                // type: "line",
                                style: {
                                    endArrow: true,
                                    startArrow: false,
                                    // animate: {
                                    //     type: "circle-running",
                                    //     repeat: true,
                                    //     duration: 2000,
                                    // },
                                    keyshape: {
                                        stroke: "#666",
                                        lineWidth: 5,
                                    },
                                },
                            };
                            return newEdge;
                        });
                        console.log("NEW EDGES");
                        console.log(newEdges);
                        setGraphData({
                            ...graphData,
                            edges: newEdges,
                        });
                        console.log("GRAPH DATA");
                        console.log(graphData);
                    }}
                />
                <ContextMenu style={{ background: "#fff" }} bindType="edge">
                    {(value) => {
                        return <MyMenu {...value} />;
                    }}
                </ContextMenu>
                <TestBehaviour />
            </Graphin>
        </div>
    );
}

export default Diagram;
