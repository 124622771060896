import { useCallback, useEffect } from "react";
import ReactFlow, {
    addEdge,
    MiniMap,
    Controls,
    Background,
    useNodesState,
    useEdgesState,
    useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";

import React from "react";
import { MarkerType } from "reactflow";

export const initialNodes = [
    {
        id: "1",
        data: {
            label: "X",
        },
        position: { x: 0, y: 250 },
        sourcePosition: "right",
        targetPosition: "left",
    },
    {
        id: "2",
        data: {
            label: "Y",
        },
        position: { x: 250, y: 150 },
        sourcePosition: "right",
        targetPosition: "left",
    },
    {
        id: "3",
        data: {
            label: "Z",
        },
        position: { x: 250, y: 350 },
        sourcePosition: "right",
        targetPosition: "left",
    },
];

const initialEdges = [
    {
        id: "e1-2",
        source: "1",
        target: "2",
        // label: "this is an edge label",
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 30,
            height: 30,
        },
    },
    {
        id: "e1-3",
        source: "1",
        target: "3",
        animated: false,
        markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 30,
            height: 30,
        },
    },
];

const onInit = (reactFlowInstance) =>
    console.log("flow loaded:", reactFlowInstance);

const OverviewFlow = (props) => {
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const { screenToFlowPosition } = useReactFlow();
    const [isConnecting, setIsConnecting] = React.useState(false);

    // Update graphData when nodes or edges change
    useEffect(() => {
        console.log(nodes);
        const nodeLabels = nodes.map((node) => node.data.label);
        // Loop through edges and add edge labels
        const edgeLabels = edges.map((edge) => {
            const sourceNode = nodes.find((node) => node.id === edge.source);
            const targetNode = nodes.find((node) => node.id === edge.target);
            return [sourceNode.data.label, targetNode.data.label];
        });
        props.setGraphData({ nodes: nodeLabels, edges: edgeLabels });
    }, [nodes, edges, props.setGraphData]);

    const onConnect = useCallback(
        (params) => {
            const newParams = {
                ...params,
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 30,
                    height: 30,
                },
            };
            setEdges((eds) => addEdge(newParams, eds));
            setTimeout(() => {
                setIsConnecting(false);
                console.log("Started connecting");
            }, 250);
        },
        [setEdges, setIsConnecting]
    );

    // On pane click, add a new node at the clicked position
    const onPaneClick = useCallback(
        (event) => {
            if (!isConnecting) {
                const newNode = {
                    id: Date.now().toString(),
                    data: { label: "New Node" },
                    position: screenToFlowPosition({
                        x: event.clientX,
                        y: event.clientY,
                    }),
                    origin: [0.5, 0.0],
                    sourcePosition: "right",
                    targetPosition: "left",
                };
                setNodes((nodes) => nodes.concat(newNode));
            } else {
                console.log("New node disallowed while connecting");
            }
        },
        [setNodes, screenToFlowPosition, isConnecting]
    );

    const onNodeDoubleClick = useCallback(
        (event, node) => {
            const newNodeName = window.prompt(
                "Enter new node name: ",
                node.data.label
            );

            if (newNodeName !== null && newNodeName !== node.data.label) {
                const newNode = {
                    ...node,
                    data: { label: newNodeName },
                };
                setNodes((nodes) => {
                    const nodeIndex = nodes.findIndex(
                        (node) => node.id === newNode.id
                    );
                    const newNodes = [...nodes];
                    newNodes[nodeIndex] = newNode;
                    return newNodes;
                });
            }
        },
        [setNodes]
    );

    const onConnectStart = useCallback(
        (event, node) => {
            setIsConnecting(true);
            console.log("Started connecting");
        },
        [setIsConnecting]
    );

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onNodeDoubleClick={onNodeDoubleClick}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onInit={onInit}
            onNodeClick={(event, node) => console.log("Clicked node", node)}
            onPaneClick={onPaneClick}
            fitView
            attributionPosition="top-right"
        >
            <MiniMap
                nodeStrokeColor={(n) => {
                    if (n.style?.background) return n.style.background;
                    if (n.type === "input") return "#0041d0";
                    if (n.type === "output") return "#ff0072";
                    if (n.type === "default") return "#1a192b";

                    return "#eee";
                }}
                nodeColor={(n) => {
                    if (n.style?.background) return n.style.background;

                    return "#fff";
                }}
                nodeBorderRadius={2}
            />
            <Controls />
            <Background color="#aaa" gap={16} />
        </ReactFlow>
    );
};

export default OverviewFlow;
